.DayPickerInput-OverlayWrapper {
    z-index: 1 !important
}

.DayPickerInput input {
    width: 125px;
}

.text-undefined {
    white-space: normal;
}